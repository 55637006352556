import { Link } from 'gatsby'
import React from 'react'
import { Twitter } from 'react-feather'

const Header = ({ location: { pathname } }) => {
  return (
    <header>
      <div className='wrapper flex justify-between items-center'>
        <div>
          <Link to='/'>
            <h1 className='text-xl md:text-2xl font-bold'>
              Wonny Log
              <span className='text-primary text-3xl ml-1 leading-4'>,</span>
            </h1>
          </Link>
        </div>
        <nav>
          <ol className='flex text-sm items-center'>
            <Link to='/writing'>
              <li
                className={`mx-3 sm:mx-4 my-5 border-b-2 border-white hover:border-primary transition duration-500 ${
                  pathname.includes('/writing') ? 'border-primary' : ''
                }`}
              >
                Writing
              </li>
            </Link>

            <Link to='/log/short-diary'>
              <li
                className={`mx-3 sm:mx-4 my-5 border-b-2 border-white hover:border-primary transition duration-500 ${
                  pathname.includes('/log') ? 'border-primary' : ''
                }`}
              >
                Log
              </li>
            </Link>

            <a href='https://twitter.com/wonny_log' target='_blank'>
              <Twitter
                className='p-2 hover:text-primary-darken transition duration-500 text-gray-500 ml-2'
                size='36px'
              />
            </a>
          </ol>
        </nav>
      </div>
    </header>
  )
}

export default Header
